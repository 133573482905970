const getDefaultState = () => {
  return {
    shop_name: null,
    uid: null,
    data: {
      item_size: "-",
      price_sum: "-",
      azukari_sum: "-",
      otsuru_sum: "-",
    }
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  setShopName(state, shop_name) {
    state.shop_name = shop_name
  },
  setUid(state, uid) {
    state.uid = uid
  },
  resetData(state){
    state.data = {
      item_size: "-",
      price_sum: "-",
      azukari_sum: "-",
      otsuru_sum: "-",
    }
  },
  setData(state, data){
    state.data = data
  }
}