import _ from 'lodash';

const getDefaultState = () => {
  return {
    isChannleSubscribed: false,
  }
}

export const state = () => (getDefaultState())
export const mutations = {
  // contact
  updateChannelSubscribed(state, payload) {
    state.isChannleSubscribed = payload
  },
}
export const actions = {
  async channelSubscribe(context){
    console.log("channelSubscribe")
    if (window.storeCtl.state.devise.uid === null) return
    var subscriptions = $nuxt.$cable.subscriptions['subscriptions'];
    console.log("subscriptions", subscriptions)
    if(subscriptions.length === 1) return
    if(subscriptions.length > 1){
      console.log("removeChannel")
      window.storeCtl.dispatch('actionCable/removeChannel')
    }
    console.log("before channelSubscribe", window.storeCtl.state.devise.uid)
    $nuxt.$cable.subscriptions.create({
      channel: "TerminalChannel",
      uid: window.storeCtl.state.devise.uid,
    }, {
      connected: () => {
        console.log(`TerminalChannel conected ${window.storeCtl.state.devise.uid}`)
        context.commit("updateChannelSubscribed", true)
      },
      disconnected: () => {
        console.log(`TerminalChannel disconnected`)
      },
      received: async (data) => {
        console.log(`TerminalChannel received`, data)
        window.storeCtl.commit('devise/setData', data.data)
      },
    })
  },

  //// チャネルの購読をとく
  removeChannel(context) {
    var subscriptions = $nuxt.$cable.subscriptions['subscriptions'];
    subscriptions.forEach((subscription) => {
      $nuxt.$cable.subscriptions.remove(subscription);
      context.commit("updateChannelSubscribed", false)
    })
  },
}
