export default(context) => {
  context.app.router.beforeEach((to, from, next)  => {
    context.app.router['referrer'] = from;

    // let path = to.path
    // // cf. to /settings/edit/ (4) ['', 'settings', 'edit', '']
    // let path_length = to.path.split("/").length
    // // console.log("to",path,path_length)
    // if(window.storeCtl.getters['auth/isLogin'] && !window.storeCtl.state.auth.isAdmin){
    //   if(
    //     (path.startsWith("/orders/") && !window.storeCtl.getters['auth/hasRight']("order")) ||
    //     (path.startsWith("/accounting/") && !window.storeCtl.getters['auth/hasRight']("regi")) ||
    //     (path.startsWith("/sales_data/") && !window.storeCtl.getters['auth/hasRight']("sales_report")) ||
    //     (path.startsWith("/tables/") && !window.storeCtl.getters['auth/hasRight']("table")) ||
    //     (path.startsWith("/masters/") && !window.storeCtl.getters['auth/hasRight']("menu")) ||
    //     (path.startsWith("/settings/") && path_length >= 4 && !path.startsWith("/settings/edit_passcode/") && !window.storeCtl.getters['auth/hasRight']("settings"))        
    //   ){
    //     window.storeCtl.commit("alert/setError", "権限が有りません")
    //     context.app.router.push("/")
    //   }
    // }
    next()
  })
}