import axios from "axios";

async function check(store){

  // アプリ・バージョンアップ
  try {
    // version.jsonファイルから最新のバージョンを取得
    const res = await axios.get("/version.json");
    const data = res.data;
    const latestVersion = data.version;

    // 環境変数から現在のバージョンを取得
    const curVersion = !!process.env.VERSION ? Number(process.env.VERSION) : 0;
    // 最新バージョンよりも古かったら、強制的にリロードする
    if (curVersion < latestVersion){
      window.location.reload(true);
    }else{
    }
  } catch(error) {
    console.error(error);
    // エラーのときはなにもしない
  }
}

// まずSettimeoutで初期チェック、その後1分毎に自動チェック
export default async ({store, route, query}) => {

  // 起動時だと処理できない箇所があったので５秒後
  window.setTimeout(async () => {
    // 諸々のチェック
    check(store)
  }, 1000 * 5);

  // 5分に1回
  window.setInterval(() => {check(store)}, 1000 * 60 * 5);
}