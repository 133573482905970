import { mapState } from 'vuex'
import { httpClient } from '~/utils/axios'
import _ from 'lodash';
import dayjs from 'dayjs';
import { isDev } from '~/utils/env'

export default {
  computed: {
    isDev(){
      return isDev()
    },
    ...mapState({
    }),
  },
  methods: {
  }
}
